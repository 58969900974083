<template>
  <div class="" id="map" style="height: 100%; width: 100%">
    <h1 class="d-flex align-items-center text-white fw-bolder p-10">Loading ....</h1>
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import io from "socket.io-client";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster";

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
export default defineComponent({
  name: "Pelanggaran",
  mounted() {
    const socket = io(ApiService.vueInstance.axios.defaults.baseURL);
    socket.emit("markerViolations");
    socket.on("markers", (data) => {
      console.log(data);
      // Handle incoming marker data and render them on the map
      // You will need to integrate Google Maps API here
    });
  },
  setup() {
    setCurrentPageTitle("Pelanggaran");
    const markersData = ref([]);

    // Function to fetch data from the API
    async function fetchDataFromAPI() {
      try {
        await ApiService.get("api/v1/app/violations")
          .then((res) => {
            // console.log(res.data.data)
            markersData.value = res.data.data.violations;
          })
          .catch((err) => {
            alert("Failed to fetch data from the API:", err);
          });
      } catch (error) {
        alert("Error fetching data:", error);
      }
    }

    // Function to initialize the map
    function initializeMap() {
      // Create a map instance
      const map = L.map("map", {
        preferCanvas: true,
      }).setView([-7.2247097, 112.4105251], 9);

      // Add a tile layer
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
        map
      );

      // Create a custom icon for the marker
      const path = window.location.origin + "/";
      const icon = path + "other/png/racing.png";
      const customIcon = L.icon({
        iconUrl: icon, // Replace with the path to your custom icon image
        iconSize: [24, 24], // Size of the icon
        iconAnchor: [16, 32], // Anchor point of the icon
      });

      // Loop through fetched markers and add them to the map
      markersData.value.forEach((markerData) => {
        const popupContent = `<div class="card"><div class="card-body text-start p-0"><div class="fs-5 fw-bolder">${markerData.violation.name}</div><div class="">${markerData.violation.alamat}, ${markerData.violation.territory_name}, ${markerData.violation.kecamatan_name} - ${markerData.violation.kabupaten_name}</div><div class="text-muted">${markerData.violation.createdAt}</div><div class="fw-bold mb-3">oleh : ${markerData.violation.created_name}</div><img class="img-fluid" src="${markerData.violation.foto}" alt="foto kegiatan" style="max-width:150px"></div></div>`;

        const customMarker = L.marker(
          new L.LatLng(
            markerData.violation.latitude,
            markerData.violation.longitude
          ),
          { icon: customIcon }
        ).bindPopup(popupContent).addTo(map); // Customize the popup content here
      });
    }

    // Use onMounted() to initialize the map after the component is mounted
    onMounted(() => {
      fetchDataFromAPI().then(() => {
        initializeMap(); // After data is fetched, initialize the map
      });
    });

    return {
      markersData, // Return the computed property
    };
  },
});
</script>
